import React from 'react';

import { Grid } from '@material-ui/core';

import { AllIndustryCard } from './AllIndustryCard';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const AllIndustryBody = ({ industries }) => {
  return (
    <Grid container direction='row' spacing={4}>
      {industries.map((industry, index) => (
        <AllIndustryCard industry={industry} key={index} />
      ))}
    </Grid>
  );
};
