import React from 'react';
import PortableText from 'react-portable-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from 'gatsby';

import { Grid, Typography, Card, CardContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.workwaveBlue,
    height: '350px',
    padding: '0px',
    borderRadius: '20px',
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
    position: 'relative',
    paddinBottom: '0px !important',
    transition: 'transform 0.25s',
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '1rem 0 0.5rem 0',
    color: '#002D5C',
  },
  pos: {
    textAlign: 'center',
    color: '#4B5B69',
  },

  button: {
    opacity: 1,
    marginTop: '-6.5rem',
    transition: 'all 0.35s ease !important',
    '&:hover': {
      opacity: '0.85 !important',
    },
  },
}));

export const AllIndustryCard = ({ industry }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card className={classes.root} elevation={0}>
        <CardContent
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: '16px',
            borderTop: '16px solid #002D5C',
          }}
        >
          <Grid item xs={12} lg={11}>
            <Grid container item direction='row' justifyContent='center'>
              <FontAwesomeIcon
                icon={['fad', industry.faIcon?.name ?? 'fish']}
                style={{
                  height: '55px',
                  width: '55px',
                  background: '#002D5C',
                  margin: '0 0 15px 0',
                  padding: '10px',
                  borderRadius: '100%',
                  '--fa-primary-color':
                    industry.faIcon?.colors[0]?.color ?? '#AA23FF',
                  '--fa-secondary-color': '#FFF',
                  '--fa-primary-opacity': 1,
                  '--fa-secondary-opacity': 1,
                }}
              />
            </Grid>
            {/* This is a band aid for pre localization. will need to change to a new field in sanity eventually. */}
            <Typography variant='body1' className={classes.title}>
              {industry.title
                .replace('Software', '')
                .replace('Business', '')
                .replace('NORAM', '')
                .replace('EMEA', '')
                .replace('APAC', '')}
            </Typography>
            <PortableText
              content={industry._rawSynopsis || []}
              className={classes.pos}
              serializers={{
                normal: ({ children }) => (
                  <Typography variant='body1'>{children}</Typography>
                ),
                li: ({ children }) => (
                  <Grid item>
                    <Typography variant='body1'>
                      <li>{children}</li>
                    </Typography>
                  </Grid>
                ),
              }}
            />
          </Grid>

          <Button
            onClick={(e) => navigate(`/${industry.slug.current}`)}
            variant='contained'
            color='primary'
            className={classes.button}
            style={{
              background: '#002D5C',
            }}
          >
            Learn More
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );
};
